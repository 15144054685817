<template>
  <div class="card">

    <div class="card-body">
      <div class="row">
        <div class="col-12">
          <b-form-group label="ลิงก์แบนเนอร์" label-for="image_link">
            <b-form-input id="image_link" v-model="form.image_link" type="text" required></b-form-input>
          </b-form-group>
        </div>
        <div class="col-6">
          <div v-if="form.home" class="d-flex flex-column align-items-center justify-content-center text-center">
            <img :src="form.home.crest" :alt="form.home.name" class="w-25 mb-1">
            <h5>{{ form.home.name }}</h5>
          </div>
          <SelectTeam :type="'home'" @addTeam="addTeam" />
        </div>
        <div class="col-6">
          <div v-if="form.away" class="d-flex flex-column align-items-center justify-content-center text-center">
            <img :src="form.away.crest" :alt="form.away.name" class="w-25 mb-1">
            <h5>{{ form.away.name }}</h5>
          </div>
          <SelectTeam :type="'away'" @addTeam="addTeam" />
        </div>
        <div class="col-12 mt-2">
          <b-form-group label="วันเวลา:" label-for="start_date">
            <flat-pickr id="start_date" v-model="form.start_date" class="form-control" :config="{
              onChange: dateChange,
              enableTime: true,
            }" />
          </b-form-group>
        </div>
      </div>
      <div class="d-flex flex-row justify-content-end align-items-center w-100">
        <router-link to="/events/soccer/next-match">
          <b-button variant="gradient-warning" class="mr-1">
            ย้อนกลับ
          </b-button>
        </router-link>
        <b-button @click="submit" variant="gradient-primary"
          :disabled="form.home === null || form.away === null || form.image_link === null || form.image_link === ''">
          บันทึก
        </b-button>
      </div>
    </div>
  </div>
</template>

<script>
import {
  BFormTextarea, BAlert, BSpinner, BFormCheckboxGroup, BFormCheckbox, BListGroupItem, BListGroup, BBadge, BOverlay, BIconController, BInputGroupPrepend, BModal, BCard, BTable, BAvatar, BFormGroup, BFormSelect, BPagination, BInputGroup, BFormInput, BButton, BCardBody
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import flatPickr from 'vue-flatpickr-component';
import moment from 'moment-timezone';
import SelectTeam from './select-team.vue';
import 'flatpickr/dist/flatpickr.css';
export default {
  components: {
    BFormTextarea, BAlert, BSpinner, BFormCheckboxGroup, BFormCheckbox, BListGroupItem, BListGroup, BBadge, BOverlay, BIconController, BInputGroupPrepend, BModal, BCard, BTable, BAvatar, BFormGroup, BFormSelect, BPagination, BInputGroup, BFormInput, BButton, BCardBody,
    flatPickr,
    SelectTeam
  },
  directives: {
    Ripple
  },
  props: ['competitions'],
  emits: ['submit'],
  filters: {
    convertTime(date) {
      return moment(date).tz('Asia/Bangkok').format('HH:ss')
    },
  },
  data: () => ({
    modal: false,
    form: {
      id: null,
      home: null,
      away: null,
      title_th: '',
      description: '',
      image_link: '',
      start_date: new Date(),
      status: 1
    },
    isLoading: false
  }),
  methods: {
    async submit() {
      this.isLoading = true;
      try {
        const { data } = await this.$http.post('events/soccerNextMatches', this.form);

        await this.$swal({
          icon: 'success',
          title: '<h3 style="color: #141414">ทำรายการสำเร็จ</h3>',
          text: data.message,
          customClass: {
            confirmButton: 'btn btn-success',
          },
        });

        await this.$router.push('/events/soccer/next-match');
      } catch (error) {
        this.$swal({
          icon: 'error',
          title: '<h3 style="color: #141414">เกิดข้อผิดพลาด!</h3>',
          text: error.response.data.message,
          customClass: {
            confirmButton: 'btn btn-primary',
          },
        })
      } finally {

        this.isLoading = false;
      }
    },
    dateChange(selectedDates, dateStr, instance) {
      this.$emit('changeDate', dateStr)
    },
    addTeam(item) {
      if (item.type === 'home') {
        this.form.home = item.team;
      } else {
        this.form.away = item.team;
      }
    }
  }
}
</script>


<style lang="scss" scoped>
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>