<template>
  <div>
    <b-button v-b-modal="`eventSoccerSelectTeam${type}Modal`" v-ripple.400="'rgba(113, 102, 240, 0.15)'"
      variant="gradient-success" block>
      เลือกทีม {{ type === 'home' ? 'Home' : 'Away' }}
    </b-button>

    <b-modal v-model="modal" size="lg" :id="`eventSoccerSelectTeam${type}Modal`"
      :title="`เลือกทีม ${type === 'home' ? 'Home' : 'Away'}`">
      <b-form-input placeholder="ค้นหา" @input="search"></b-form-input>

      <div class="text-center mt-2" v-if="isLoading">
        <b-spinner variant="primary" type="grow" label="Spinning"></b-spinner>
        <b-spinner variant="primary" type="grow" label="Spinning"></b-spinner>
        <b-spinner variant="primary" type="grow" label="Spinning"></b-spinner>
      </div>

      <div class="row" v-else>
        <div class="col-2 border p-2 cursor-pointer" v-for="team in teams" @click="addTeam(team, type)">
          <div class="d-flex flex-column align-items-center justify-content-center text-center h-100">
            <img :src="team.crest" :alt="team.name" class="w-100 mb-1">
            <h5>{{ team.name }}</h5>
          </div>
        </div>
      </div>

      <template #modal-footer>
        <b-button variant="success" @click="submit()">
          ยืนยัน
        </b-button>
        <b-button variant="danger" @click="cancel()">
          ยกเลิก
        </b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import {
  BFormTextarea, BAlert, BSpinner, BFormCheckboxGroup, BFormCheckbox, BListGroupItem, BListGroup, BBadge, BOverlay, BIconController, BInputGroupPrepend, BModal, BCard, BTable, BAvatar, BFormGroup, BFormSelect, BPagination, BInputGroup, BFormInput, BButton, BCardBody
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import flatPickr from 'vue-flatpickr-component';
import moment from 'moment-timezone';
import 'flatpickr/dist/flatpickr.css';
import * as _ from 'lodash';

export default {
  components: {
    BFormTextarea, BAlert, BSpinner, BFormCheckboxGroup, BFormCheckbox, BListGroupItem, BListGroup, BBadge, BOverlay, BIconController, BInputGroupPrepend, BModal, BCard, BTable, BAvatar, BFormGroup, BFormSelect, BPagination, BInputGroup, BFormInput, BButton, BCardBody,
    flatPickr
  },
  directives: {
    Ripple
  },
  props: ['type'],
  emits: ['addTeam'],
  filters: {
    convertTime(date) {
      return moment(date).tz('Asia/Bangkok').format('HH:ss')
    },
  },
  data: () => ({
    modal: false,
    isLoading: false,
    form: {
      id: null,
      title_th: '',
      description: '',
      image_link: '',
      status: 0
    },
    teams: []
  }),
  methods: {
    async fetchTeams(term) {
      this.isLoading = true;
      try {
        const { data } = await this.$http.get('/events/soccerSearchTeams', {
          params: {
            term: term
          }
        });

        this.teams = data;
      } catch (error) {
        console.log(error);
      } finally {
        this.isLoading = false;
      }
    },
    addTeam(team, type) {
      this.$emit('addTeam', { team, type })
      this.modal = false
    },
    cancel() {
      this.modal = false;
    },
    search: _.debounce(function (val) {
      this.fetchTeams(val);
    }, 400)
  }
}
</script>


<style lang="scss" scoped>
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>